export default [
  'JSON',
  'Polygon',
  'Ring',
  'Point',
  'SimpleAggregateFunction',
  'MultiPolygon',
  'IPv6',
  'UInt32',
  'IntervalYear',
  'IntervalQuarter',
  'IntervalMonth',
  'Int64',
  'IntervalDay',
  'IntervalHour',
  'IPv4',
  'IntervalSecond',
  'LowCardinality',
  'Int16',
  'UInt256',
  'AggregateFunction',
  'Nothing',
  'Decimal256',
  'Tuple',
  'Array',
  'IntervalMicrosecond',
  'Bool',
  'Enum16',
  'IntervalMinute',
  'FixedString',
  'String',
  'DateTime',
  'Object',
  'Map',
  'UUID',
  'Decimal64',
  'Nullable',
  'Enum',
  'Int32',
  'UInt8',
  'Date',
  'Decimal32',
  'UInt128',
  'Float64',
  'Nested',
  'UInt16',
  'IntervalMillisecond',
  'Int128',
  'Decimal128',
  'Int8',
  'Decimal',
  'Int256',
  'DateTime64',
  'Enum8',
  'DateTime32',
  'Date32',
  'IntervalWeek',
  'UInt64',
  'IntervalNanosecond',
  'Float32',
  'bool',
  'INET6',
  'INET4',
  'ENUM',
  'BINARY',
  'GEOMETRY',
  'NATIONAL CHAR VARYING',
  'BINARY VARYING',
  'NCHAR LARGE OBJECT',
  'NATIONAL CHARACTER VARYING',
  'boolean',
  'NATIONAL CHARACTER LARGE OBJECT',
  'NATIONAL CHARACTER',
  'NATIONAL CHAR',
  'CHARACTER VARYING',
  'LONGBLOB',
  'TINYBLOB',
  'MEDIUMTEXT',
  'TEXT',
  'VARCHAR2',
  'CHARACTER LARGE OBJECT',
  'DOUBLE PRECISION',
  'LONGTEXT',
  'NVARCHAR',
  'INT1 UNSIGNED',
  'VARCHAR',
  'CHAR VARYING',
  'MEDIUMBLOB',
  'NCHAR',
  'VARBINARY',
  'CHAR',
  'SMALLINT UNSIGNED',
  'TIMESTAMP',
  'FIXED',
  'TINYTEXT',
  'NUMERIC',
  'DEC',
  'TIME',
  'FLOAT',
  'SET',
  'TINYINT UNSIGNED',
  'INTEGER UNSIGNED',
  'INT UNSIGNED',
  'CLOB',
  'MEDIUMINT UNSIGNED',
  'BLOB',
  'REAL',
  'SMALLINT',
  'INTEGER SIGNED',
  'NCHAR VARYING',
  'INT SIGNED',
  'TINYINT SIGNED',
  'BIGINT SIGNED',
  'BINARY LARGE OBJECT',
  'SMALLINT SIGNED',
  'YEAR',
  'MEDIUMINT',
  'INTEGER',
  'INT1 SIGNED',
  'BIT',
  'BIGINT UNSIGNED',
  'BYTEA',
  'INT',
  'SINGLE',
  'MEDIUMINT SIGNED',
  'DOUBLE',
  'INT1',
  'CHAR LARGE OBJECT',
  'TINYINT',
  'BIGINT',
  'CHARACTER',
  'BYTE',
];
