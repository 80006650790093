export default [
  'SELECT',
  'CASE',
  'THEN',
  'DISTINCT',
  'INSERT',
  'UPDATE',
  'DELETE',
  'WHERE',
  'AND',
  'OR',
  'OFFSET',
  'HAVING',
  'AS',
  'GLOBAL',
  'FROM',
  'WHEN',
  'ELSE',
  'END',
  'TYPE',
  'LEFT',
  'RIGHT',
  'USING',
  'JOIN',
  'ON',
  'OUTER',
  'DESC',
  'ASC',
  'CREATE',
  'TABLE',
  'PRIMARY',
  'KEY',
  'FOREIGN',
  'NOT',
  'REFERENCES',
  'DEFAULT',
  'INNER',
  'CROSS',
  'NATURAL',
  'DATABASE',
  'DROP',
  'GRANT',
  'ANY',
  'BETWEEN',
  'ATTACH',
  'DETACH',
  'CAST',
  'WITH',
  'BIT_AND',
  'BIT_OR',
  'BIT_XOR',
  'DESCRIBE',
  'OPTIMIZE',
  'PREWHERE',
  'TOTALS',
  'DATABASES',
  'PROCESSLIST',
  'SHOW',
  'LIMIT',
  'IF',
  'IF NOT EXISTS',
  'IF EXISTS',
  'GROUP',
  'ORDER',
  'BY',
  'IN',
  'FORMAT',
  'GROUP BY',
  'ORDER BY',
  'UNION ALL',
  'UNION DISTINCT',
  'ALTER',
];
